export default {
  hello: "hello",
  usd: "usd",
  uzs: "uzs",
  "Кто может участвовать в конкурсе?": "Кто может участвовать в конкурсе?",
  "В конкурсе могут участвовать только граждане Узбекистана, не достигшие 30 летнего возраста в момент регистрации.":
    "В конкурсе могут участвовать только граждане Узбекистана, не достигшие 30 летнего возраста в момент регистрации.",
  "В конкурсе могут участвовать только граждане Узбекистана от 16 до 30 лет. При этом, участник должен быть не достигшим 30 летнего возраста в момент регистрации.":
    "В конкурсе могут участвовать только граждане Узбекистана от 16 до 30 лет. При этом, участник должен быть не достигшим 30 летнего возраста в момент регистрации.",
  "Почему в конкурсе могут участвовать лица только до 30 лет?":
    "Почему в конкурсе могут участвовать лица только до 30 лет?",
  "Согласно Постановлению Президента № ПП-357 от 23 августа 2022 года, конкурс нацелен на поддержку молодежных стартапов в сфере IT.":
    "Согласно Постановлению Президента № ПП-357 от 23 августа 2022 года, конкурс нацелен на поддержку молодежных стартапов в сфере IT.",
  "Как проходить регистрацию гражданам РУз?":
    "Как проходить регистрацию гражданам РУз?",
  "Для участия в конкурсе все члены команд с гражданством Республики Узбекистан регистрируются на портале через единую информационную систему идентификации пользователей системы «Электронное правительство» (OneID).":
    "Для участия в конкурсе все члены команд с гражданством Республики Узбекистан регистрируются на портале через единую информационную систему идентификации пользователей системы «Электронное правительство» (OneID).",
  "Смогут ли иностранные граждане принять участие на конкурсе?":
    "Смогут ли иностранные граждане принять участие на конкурсе?",
  "В основном направлении конкурса могут принимать участие исключительно граждане Республики Узбекистан, а в специальном направлении - также иностранные граждане, являющиеся сотрудниками резидентов IT Park.":
    "В основном направлении конкурса могут принимать участие исключительно граждане Республики Узбекистан, а в специальном направлении - также иностранные граждане, являющиеся сотрудниками резидентов IT Park.",
  "Как проходить регистрацию иностранцам?":
    "Как проходить регистрацию иностранцам?",
  "Иностранные граждане и лица без гражданства - команды, участвующие в специальном направлении, регистрируются через форму, доступную на портале.":
    "Иностранные граждане и лица без гражданства - команды, участвующие в специальном направлении, регистрируются через форму, доступную на портале.",
  "Может ли одна команда или участник участвовать в различных направлениях?":
    "Может ли одна команда или участник участвовать в различных направлениях?",
  "Одна команда может принять участие только в одном направлении конкурса.":
    "Одна команда может принять участие только в одном направлении конкурса.",
  "Как будет проводиться специальное направление?":
    "Как будет проводиться специальное направление?",
  "Состоится презентация проектов по специальному направлению конкурса. Команды-участники специального направления разрабатывают прототип программного продукта в течение 72 часов на особых условиях, представляют и защищают свои проекты в ответственной организации.":
    "Состоится презентация проектов по специальному направлению конкурса. Команды-участники специального направления разрабатывают прототип программного продукта в течение 72 часов на особых условиях, представляют и защищают свои проекты в ответственной организации.",
  "Является ли участие на конкурсе бесплатным?":
    "Является ли участие на конкурсе бесплатным?",
  "Да, участие является бесплатным.": "Да, участие является бесплатным.",
  "Можно ли командам принимать участие одновременно по двум и более категориям в основном направлении?":
    "Можно ли командам принимать участие одновременно по двум и более категориям в основном направлении?",
  "Участвовать разрешается только по 1 выбранной категории основного направления, а также в специальном направлении.":
    "Участвовать разрешается только по 1 выбранной категории основного направления, а также в специальном направлении.",
  "Как будет проходить онлайн голосование?":
    "Как будет проходить онлайн голосование?",
  "Это будет общественное голосование на специальном портале, где голосующие будут авторизоваться через OneID. Голосование пройдет на основе 5 бальной системы: внешний вид проекта; функционал проекта; удобство проекта; готовность (полноценность) проекта и значимость проекта. Исходя из результатов голосования будут определены победители основных направлений.":
    "Это будет общественное голосование на специальном портале, где голосующие будут авторизоваться через OneID. Голосование пройдет на основе 5 бальной системы: внешний вид проекта; функционал проекта; удобство проекта; готовность (полноценность) проекта и значимость проекта. Исходя из результатов голосования будут определены победители основных направлений.",
  "Проекты какой стадии могут подать заявку на конкурс?":
    "Проекты какой стадии могут подать заявку на конкурс?",
  "Так как основной целью конкурса является определение экспорто-ориентированных стартапов и готовых к выходу на международные рынки, мы ожидаем стартапы с уже работающим MVP, у которого уже есть пользователи и продажи. К сожалению, стартапы на этапе идеи не смогут пройти первичный отбор.":
    "Так как основной целью конкурса является определение экспорто-ориентированных стартапов и готовых к выходу на международные рынки, мы ожидаем стартапы с уже работающим MVP, у которого уже есть пользователи и продажи. К сожалению, стартапы на этапе идеи не смогут пройти первичный отбор.",
  "Технологии на основе искусственного интеллекта":
    "Технологии на основе искусственного интеллекта",
  "Цифровые технологии в социальной сфере":
    "Цифровые технологии в социальной сфере",
  "Технологии в сфере кибербезопасности":
    "Технологии в сфере кибербезопасности",
  "Предпринимательство и финансовые технологии":
    "Предпринимательство и финансовые технологии",
  "Компьютерные и мобильные игры": "Компьютерные и мобильные игры",
  "Специальное направление - Хакатон": "Специальное направление - Хакатон",
  "Приветствуем Вас на сайте конкурса President Tech Award! Пожалуйста, оставьте свои контактные данные по форме ниже. Мы отправим Вам уведомление о старте регистрации на конкурс.":
    "Приветствуем Вас на сайте конкурса President Tech Award! Пожалуйста, оставьте свои контактные данные по форме ниже. Мы отправим Вам уведомление о старте регистрации на конкурс.",
  "Ваше ФИО:": "Ваше ФИО:",
  ФИО: "ФИО",
  "Контактный номер:": "Контактный номер:",
  "Электронная почта:": "Электронная почта:",
  "Электронная почта": "Электронная почта",
  Отправить: "Отправить",
  "Благодарим Вас за проявленный интерес к конкурсу President Tech Award! <b>1 мая 2023</b> года откроется регистрация для всех желающих принять участие в конкурсе лучших стартапов. Желаем удачи!":
    "Благодарим Вас за проявленный интерес к конкурсу President Tech Award! <b>1 мая 2023</b> года откроется регистрация для всех желающих принять участие в конкурсе лучших стартапов. Желаем удачи!",
  Спасибо: "Спасибо",
  Главная: "Главная",
  Войти: "Войти",
  Направления: "Направления",
  Правила: "Правила",
  Контакты: "Контакты",
  "Технологии на основе искусственного интеллекта":
    "Технологии на основе искусственного интеллекта",
  "Решения для различных отраслей на основе технологий искусственного интеллекта":
    "Решения для различных отраслей на основе технологий искусственного интеллекта",
  "Цифровые технологии в социальной сфере":
    "Цифровые технологии в социальной сфере",
  "Проекты, направленные на цифровизацию операционных процессов в образовании, здравоохранении, туризме, транспорте и логистике, электронный перевод государственных услуг или решение существующих проблем в социальных сферах":
    "Проекты, направленные на цифровизацию операционных процессов в образовании, здравоохранении, туризме, транспорте и логистике, электронный перевод государственных услуг или решение существующих проблем в социальных сферах",
  "Технологии в сфере кибербезопасности":
    "Технологии в сфере кибербезопасности",
  "Проекты, направленные на обеспечение безопасности программных продуктов и программно-аппаратных комплексов в различных сферах, предотвращение киберпреступлений":
    "Проекты, направленные на обеспечение безопасности программных продуктов и программно-аппаратных комплексов в различных сферах, предотвращение киберпреступлений",
  "Предпринимательство и финансовые технологии":
    "Предпринимательство и финансовые технологии",
  "Проекты, направленные на создание электронной коммерции, корпоративных решений и финансовых технологий для субъектов хозяйствования и хозяйственных объединений":
    "Проекты, направленные на создание электронной коммерции, корпоративных решений и финансовых технологий для субъектов хозяйствования и хозяйственных объединений",
  "Компьютерные и мобильные игры": "Компьютерные и мобильные игры",
  "Проекты, направленные на разработку офлайн- или онлайн-игр для компьютеров и мобильных устройств и геймификацию процессов":
    "Проекты, направленные на разработку офлайн- или онлайн-игр для компьютеров и мобильных устройств и геймификацию процессов",
  "Специальное направление - Хакатон": "Специальное направление - Хакатон",
  "72 часовой хакатон, где нужно разработать прототипа проекта на основе поставленных задач":
    "72 часовой хакатон, где нужно разработать прототипа проекта на основе поставленных задач",
  "Призовой фонд": "Призовой фонд",
  "Общий призовой фонд 1 000 000 $": "Общий призовой фонд 1 000 000 $",
  "5 Основных направлений:": "5 Основных направлений:",
  "1 место": "1 место",
  "2 место": "2 место",
  "3 место": "3 место",
  "Специальное направление": "Специальное направление",
  "Необходимые материалы для заполнения заявки":
    "Необходимые материалы для заполнения заявки",
  "Аккаунт в OneID для регистрации": "Аккаунт в OneID для регистрации",
  "Видео описывающее проект": "Видео описывающее проект",
  "(не более 3 минут)": "(не более 3 минут)",
  "Презентация проекта (PDF формат) - загрузка файла или ссылки на файл":
    "Презентация проекта (PDF формат) - загрузка файла или ссылки на файл",
  "Ссылка на прототип проекта (MVP)": "Ссылка на прототип проекта (MVP)",
  "Ссылка проекта на GitHub": "Ссылка проекта на GitHub",
  "Условия участия": "Условия участия",
  "Количество заявок": "Количество заявок",
  "Допускается подача заявки на участие в конкурсе только по 1 основному и спец. направлению.":
    "Допускается подача заявки на участие в конкурсе только по 1 основному и спец. направлению.",
  "Количество участников в команде": "Количество участников в команде",
  "Для участия в конкурсе состав команд должен состоять из 3-8 человек по основным направлениям и 3-5 человек по специальному направлению.":
    "Для участия в конкурсе состав команд должен состоять из 3-8 человек по основным направлениям и 3-5 человек по специальному направлению.",
  "Стоимость участия": "Стоимость участия",
  "Плата за участие в конкурсе не взимается. (Участие в конкурсе – бесплатно)":
    "Плата за участие в конкурсе не взимается. (Участие в конкурсе – бесплатно)",
  "Покрытие расходов": "Покрытие расходов",
  "Расходы, связанные с реализацией проектов, разработанных в рамках конкурса, не возмещаются.":
    "Расходы, связанные с реализацией проектов, разработанных в рамках конкурса, не возмещаются.",
  "Изменения в команде": "Изменения в команде",
  "Изменения в составе команды возможны при условии согласования с организационным комитетом.":
    "Изменения в составе команды возможны при условии согласования с организационным комитетом.",
  "Условия отбора участников в состав команды":
    "Условия отбора участников в состав команды",
  "В основном направлении конкурса могут участвовать граждане Узбекистана, не достигшие 30 лет на день подачи заявки. В специальном направлении могут участвовать иностранные граждане и лица без гражданства, работающие в компаниях резидентах IT Park.":
    "В основном направлении конкурса могут участвовать граждане Узбекистана, не достигшие 30 лет на день подачи заявки. В специальном направлении могут участвовать иностранные граждане и лица без гражданства, работающие в компаниях резидентах IT Park.",
  Таймлайн: "Таймлайн",
  "Предварительный отбор": "Предварительный отбор",
  "Онлайн оценивание проектов": "Онлайн оценивание проектов",
  "Онлайн голосование": "Онлайн голосование",
  "Прием заявок": "Прием заявок",
  Bootcamp: "Bootcamp",
  "Demo Day": "Demo Day",
  Награждение: "Награждение",
  Галерея: "Галерея",
  "Показать все фотографии": "Показать все фотографии",
  Новости: "Новости",
  "Спонсоры и партнеры": "Спонсоры и партнеры",
  "Часто задаваемые вопросы": "Часто задаваемые вопросы",
  "за 1 место": "за 1 место",
  "Кто может участвовать в конкурсе?": "Кто может участвовать в конкурсе?",
  "Сколько человек должны быть в команде и должны ли все подавать заявки?":
    "Сколько человек должны быть в команде и должны ли все подавать заявки?",
  "Что нужно для подачи заявки?": "Что нужно для подачи заявки?",
  "Каким должно быть приложение для подачи заявки?":
    "Каким должно быть приложение для подачи заявки?",
  "Кто будет оценивать проекты?": "Кто будет оценивать проекты?",
  "Может ли один участник участвовать в различных командах?":
    "Может ли один участник участвовать в различных командах?",
  "Должны ли студенты сами разработать приложение?":
    "Должны ли студенты сами разработать приложение?",
  "Можно ли участвовать с проектом не подходящим под категории?":
    "Можно ли участвовать с проектом не подходящим под категории?",
  "приветствуем вас на сайте конкурса President Tech Award":
    "приветствуем вас на сайте конкурса President Tech Award",
  "veb-saytiga xush kelibsiz": "veb-saytiga xush kelibsiz",
  "ваше ФИО": "ваше ФИО",
  "контактный номер": "контактный номер",
  "электронная почта": "электронная почта",
  отправить: "отправить",
  "благодарим вас за проявленный интерес к конкурсу President tech award":
    "благодарим вас за проявленный интерес к конкурсу President tech award",
  "qiziqganingiz uchun tashakkur": "qiziqganingiz uchun tashakkur",
  "1 мая 2023 года откроется регистрация для всех желающих принять участие в конкурсе лучших стартапов":
    "1 мая 2023 года откроется регистрация для всех желающих принять участие в конкурсе лучших стартапов",
  "eng yaxshi startaplar tanlovida ishtirok etishni xohlovchilar uchun ro‘yxatdan o‘tish, 2023-yil 1-maydan boshlanadi.":
    "eng yaxshi startaplar tanlovida ishtirok etishni xohlovchilar uchun ro‘yxatdan o‘tish, 2023-yil 1-maydan boshlanadi.",
  "желаем удачи": "желаем удачи",
  "sizga omad": "sizga omad",
  спасибо: "спасибо",
  "Конкурс лучших стартапов с общим призовым фондом в":
    "Конкурс лучших стартапов с общим призовым фондом в",
  "President Tech Award – это конкурс на лучшие цифровые продукты по 5 основным и 1 специальному направлению. Целью которого является привлечь внимание молодежи к сфере IT-технологий и вдохновить их на создание инновационных решений и разработок, способных конкурировать на мировом рынке.":
    "President Tech Award – это конкурс на лучшие цифровые продукты по 5 основным и 1 специальному направлению. Целью которого является привлечь внимание молодежи к сфере IT-технологий и вдохновить их на создание инновационных решений и разработок, способных конкурировать на мировом рынке.",
  "Видео описывающее проект (не более 3 минут)":
    "Видео описывающее проект (не более 3 минут)",
  "20 Марта, 2023": "20 Марта, 2023",
  "26 Марта, 2023": "26 Марта, 2023",
  "23 Марта, 2023": "23 Марта, 2023",
  "Roadshow: Встреча с молодежью в рамках конкурса «mGovAward»":
    "Roadshow: Встреча с молодежью в рамках конкурса «mGovAward»",
  "Во всех областях Узбекистана проводятся семинары mGovAward":
    "Во всех областях Узбекистана проводятся семинары mGovAward",
  "Roadshow в рамках конкурса «mGovAward» продолжается":
    "Roadshow в рамках конкурса «mGovAward» продолжается",
  "Показать все новости": "Показать все новости",
  Телефон: "Телефон",
  "Ваш результат поиска по TEAM ID: number":
    "Ваш результат поиска по TEAM ID: {number}",
  "К вам поступил запрос от fullname на вступление в команду.":
    "К вам поступил запрос от <b>{fullname}</b> на вступление в команду.",
  "Ваш запрос на вступление в команду team_name отправлен. Ожидайте решение.":
    "Ваш запрос на вступление в команду <b>{team_name}</b> отправлен. Ожидайте решение.",
  "Вы приняли fullname в свою команду.":
    "Вы приняли <b>{fullname}</b> в свою команду.",
  "Поздравляем! Вы приняты в команду team_name!":
    "Поздравляем! Вы приняты в команду <b>{team_name}</b>!",
  "Вы отказали fullname на вступление в вашу команду.":
    "Вы отказали <b>{fullname}</b> на вступление в вашу команду.",
  "Вам отказали на вступление в команду team_name.":
    "Вам отказали на вступление в команду <b>{team_name}</b>.",
  "Вы удалили fullname из вашей команды.":
    "Вы удалили <b>{fullname}</b> из вашей команды.",
  "Вас удалили из команды team_name.":
    "Вас удалили из команды <b>{team_name}</b>.",
  "fullname покинул вашу команду.": "<b>{fullname}</b> покинул вашу команду.",
  "Вы покинули команду team_name.": "Вы покинули команду <b>{team_name}</b>.",
  "Ссылка на Livechat President Tech Award":
    "Ссылка на Livechat President Tech Award",
  "Ссылка на телеграм канал конкурса President Tech Award":
    "Ссылка на телеграм канал конкурса President Tech Award",
  "Ваша заявка еще не сформирована.<br> Обратитесь к капитану команды.":
    "Ваша заявка еще не сформирована.<br> Обратитесь к капитану команды.",
  "Ваша заявка на участие в основном направлении оформлена и передана на рассмотрение.":
    "Ваша заявка на участие в основном направлении оформлена и передана на рассмотрение.",
  "Ваша заявка на участие в Хакатоне оформлена и передана на рассмотрение.":
    "Ваша заявка на участие в Хакатоне оформлена и передана на рассмотрение.",
  "Поздравляем! Ваша заявка на участие в Хакатоне подтверждена.":
    "Поздравляем! Ваша заявка на участие в Хакатоне подтверждена.",
  "Поздравляем! Ваша заявка на участие в основном направлении конкурса подтверждена.":
    "Поздравляем! Ваша заявка на участие в основном направлении конкурса подтверждена.",
  "Ваша заявка на участие в Хакатоне возвращена на доработку.":
    "Ваша заявка на участие в Хакатоне возвращена на доработку.",
  "Ваша заявка на участие в основном направлении возвращена на доработку.":
    "Ваша заявка на участие в основном направлении возвращена на доработку.",
  "Вы не сможете удалить команду пока в ней находятся другие участники!":
    "Вы не сможете удалить команду пока в ней находятся другие участники!",
  Внимание: "Внимание",
  "Для граждан Республики Узбекистан": "Для граждан Республики Узбекистан",
  "Для иностранных граждан": "Для иностранных граждан",
  "Нет аккаунта?": "Нет аккаунта?",
  Зарегистрироваться: "Зарегистрироваться",
  "Регистрация граждан Республики Узбекистан проходит через Единая система идентификации (OneID).":
    "Регистрация граждан Республики Узбекистан проходит через Единая система идентификации (OneID).",
  Авторизация: "Авторизация",
  "публичной оферты": "публичной оферты",
  "Нажимая кнопку “Авторизация”, вы принимаете условия":
    "Нажимая кнопку “Авторизация”, вы принимаете условия",
  "Зарегистрироваться на участие в конкурсе President Tech Award":
    "Зарегистрироваться на участие в конкурсе President Tech Award",
  ПИНФЛ: "ПИНФЛ",
  Пол: "Пол",
  "Дата рождения": "Дата рождения",
  "Место проживания (Регион)": "Место проживания (Регион)",
  "Место проживания (Район)": "Место проживания (Район)",
  "Номер телефона": "Номер телефона",
  "Роль участника": "Роль участника",
  "текущее место работы/учебы": "текущее место работы/учебы",
  "Код отправлен на ваш номер в смс": "Код отправлен на ваш номер в смс",
  "Вход для иностранных граждан": "Вход для иностранных граждан",
  Пароль: "Пароль",
  "Повторите пароль": "Повторите пароль",
  "Мой профиль": "Мой профиль",
  "На рассмотрении": "На рассмотрении",
  "Дата регистрации": "Дата регистрации",
  "Дата рождения:": "Дата рождения:",
  "Гражданство:": "Гражданство:",
  "Место проживания:": "Место проживания:",
  "Номер телефона:": "Номер телефона:",
  "Роль участника:": "Роль участника:",
  "Текущее место работы:": "Текущее место работы:",
  "Письмо подтверждающее то, что вы работаете в компании резидента IT Park":
    "Письмо подтверждающее то, что вы работаете в компании резидента IT Park",
  "Копия паспорта:": "Копия паспорта:",
  Уведомления: "Уведомления",
  Сообщество: "Сообщество",
  "часто задаваемые вопросы": "часто задаваемые вопросы",
  выход: "выход",
  "Заявки команд на конкурс": "Заявки команд на конкурс",
  "ID команды": "ID команды",
  "Название Команды": "Название Команды",
  "дата регистрации (от)": "дата регистрации (от)",
  "дата регистрации (до)": "дата регистрации (до)",
  Регион: "Регион",
  "направление конкурса": "направление конкурса",
  "статус заявки": "статус заявки",
  "количество участников": "количество участников",
  Статус: "Статус",
  подтвержден: "подтвержден",
  "возвращено на доработку": "возвращено на доработку",
  "на рассмотрении": "на рассмотрении",
  "логотип команды": "логотип команды",
  "название команды": "название команды",
  "регион команды": "регион команды",
  "Роль участника": "Роль участника",
  "Регистрация иностранных граждан": "Регистрация иностранных граждан",
  Выберите: "Выберите",
  "количество участников": "количество участников",
  "Номер телефона:": "Номер телефона:",
  "электронная почта": "электронная почта",
  статус: "статус",
  "Список участников": "Список участников",
  ПИНФЛ: "ПИНФЛ",
  ФИО: "ФИО",
  Пол: "Пол",
  "Гражданство:": "Гражданство:",
  возраст: "возраст",
  "место жительства": "место жительства",
  Далее: "Далее",
  Закрыть: "Закрыть",
  "Эта электронная почта уже зарегистрирована на платформе":
    "Эта электронная почта уже зарегистрирована на платформе",
  Внимание: "Внимание",
  Выход: "Выход",
  "Место проживания": "Место проживания",
  "Дополнительный номер телефона": "Дополнительный номер телефона",
  "Моя команда": "Моя команда",
  FAQ: "Часто задаваемые вопросы",
  "E-mail": "Электронная почта",
  Подтвержден: "Подтвержден",
  Мужчина: "Мужчина",
  Женщина: "Женщина",
  "Текущее место работы/учебы": "Текущее место работы/учебы",
  "Текущее место работы": "Текущее место работы",
  Редактирование: "Редактирование",
  "Место проживания (Регион)": "Место проживания (Регион)",
  "Место проживания (Район)": "Место проживания (Район)",
  Сохранить: "Сохранить",
  "Основная команда": "Основная команда",
  Хакатон: "Хакатон",
  Название: "Название",
  "Информация о команде": "Информация о команде",
  "Заявка на конкурс": "Заявка на конкурс",
  Участники: "Участники",
  "Запросы на вступление": "Запросы на вступление",
  "Регион команды": "Регион команды",
  "Название проекта": "Название проекта",
  "Описание проекта на узбекском": "Описание проекта на узбекском",
  "Описание проекта на русском": "Описание проекта на русском",
  "Описание проекта на английском": "Описание проекта на английском",
  "Ссылка на видеозапись, описывающий проект (до 3 минут)":
    "Ссылка на видеозапись, описывающий проект (до 3 минут)",
  "Презентация проекта (файл в формате PDF до 20 Мб)":
    "Презентация проекта (файл в формате PDF до 20 Мб)",
  "Ссылка на GitHub проекта (Нужно открыть доступ к данному аккаунту - awardsgovuz)":
    "Ссылка на GitHub проекта (Нужно открыть доступ к данному аккаунту - awardsgovuz)",
  "Ссылка на GitHub проекта": "Ссылка на GitHub проекта",
  "Доступы на прототип проекта (MVP)": "Доступы на прототип проекта (MVP)",
  "Например, логин и пароль": "Например, логин и пароль",
  "Логотип проекта в высоком качестве (в формате jpeg, jpg, png до 5 Мб)":
    "Логотип проекта в высоком качестве (в формате jpeg, jpg, png до 5 Мб)",
  "Отправить заявку": "Отправить заявку",
  "Выберите файл": "Выберите файл",
  "Отправлять приглашение": "Отправлять приглашение",
  Действие: "Действие",
  Капитан: "Капитан",
  Удалить: "Удалить",
  Принято: "Принято",
  "Логотип команды": "Логотип команды",
  "Название команды": "Название команды",
  "История действий": "История действий",
  Исполнитель: "Исполнитель",
  "Дата действия": "Дата действия",
  "Статус действия": "Статус действия",
  Комментарий: "Комментарий",
  "Контактный номер": "Контактный номер",
  "Добавить контактный номер": "Добавить контактный номер",
  "Вы уверены, что хотите удалить этого участника?":
    "Вы уверены, что хотите удалить этого участника?",
  Да: "Да",
  Нет: "Нет",
  Гражданство: "Гражданство",
  "Копия паспорта (в формате PDF до 15 Мб)":
    "Копия паспорта (в формате PDF до 15 Мб)",
  "Письмо подтверждающее о том, что вы работаете в компании резидента IT Park":
    "Письмо подтверждающее о том, что вы работаете в компании резидента IT Park",
  "Код или электронная почта могли быть введены неправильно":
    "Код или электронная почта могли быть введены неправильно",
  "Код отправлен на ваш E-mail": "Код отправлен на ваш E-mail",
  "Введите код": "Введите код",
  Назад: "Назад",
  "Копия паспорта": "Копия паспорта",
  Открыть: "Открыть",
  Изменить: "Изменить",
  "Ваш профиль возвращено на доработку.":
    "Ваш профиль возвращено на доработку.",
  Причина: "Причина",
  "Поздравляем! Ваш профиль подтвержден.":
    "Поздравляем! Ваш профиль подтвержден.",
  "Изменить пароль": "Изменить пароль",
  "Текущий пароль": "Текущий пароль",
  "Новый пароль": "Новый пароль",
  "Повторите новый пароль": "Повторите новый пароль",
  "У вас еще нет команды!": "У вас еще нет команды!",
  "Вы можете создать новую команду или присоединиться к команде":
    "Вы можете создать новую команду или присоединиться к команде",
  "Создать команду": "Создать команду",
  "Присоединиться к команде": "Присоединиться к команде",
  Логотип: "Логотип",
  "Введите TEAM ID для вступления в команду":
    "Введите TEAM ID для вступления в команду",
  "Team ID": "Team ID",
  Поиск: "Поиск",
  "Ваш результат поиска по TEAM ID: number":
    "Ваш результат поиска по TEAM ID: number",
  "Отправить запрос": "Отправить запрос",
  "Количество участников должно быть от 3 до 5 человек":
    "Количество участников должно быть от 3 до 5 человек",
  "Количество участников должно быть от 3 до 8 человек":
    "Количество участников должно быть от 3 до 8 человек",
  "Ссылка на восстановление аккаунта отправлено на вашу почту!":
    "Ссылка на восстановление аккаунта отправлено на вашу почту!",
  "Восстановить аккаунт": "Восстановить аккаунт",
  "Забыли пароль?": "Забыли пароль?",
  "Показать еще": "Показать еще",
  "Участники достигшие возраста 30 лет на момент регистрации не могут участвовать в конкурсе.":
    "Участники достигшие возраста 30 лет на момент регистрации не могут участвовать в конкурсе.",
  "Запросы на удаление": "Запросы на удаление",
  "Капитан команды": "Капитан команды",
  "Причина удаления": "Причина удаления",
  Отклонен: "Отклонен",
  "Причина отклонения": "Причина отклонения",
  "Дата и время": "Дата и время",
  "Ваш запрос на удаление команды отправлен на рассмотрение.":
    "Ваш запрос на удаление команды отправлен на рассмотрение.",
  "Ваша команда team_name удалена.": "Ваша команда {team_name} удалена.",
  "Ваш запрос на удаление команды отклонен.":
    "Ваш запрос на удаление команды отклонен.",
  redactor: "Админ по PR",
  registration: "Админ по заявкам",
  admin: "Супер админ",
  Игра: "Игра",
  Играть: "Играть",
  ПРОДОЛЖИТЬ: "ПРОДОЛЖИТЬ",
  Тест: "Тест",
  "Выберите понравившийся проект из категории direction_name":
    "Выберите понравившийся проект из категории<br> “{direction_name}”",
  "Телеграм канал": "Телеграм канал",
  "Телеграм группа": "Телеграм группа",
  "Шаблон презентации:": "Шаблон презентации:",
  файл: "файл",
  "Процесс создания и формирования команды для участия в конкурсе завершен 30 июня 2023 года":
    "Процесс создания и формирования команды для участия в конкурсе завершен 30 июня 2023 года",
  "Подача заявки на участие в конкурсе завершена 30 июня 2023 года":
    "Подача заявки на участие в конкурсе завершена 30 июня 2023 года",
  "Вы не можете удалить участника команды. В команде должно быть минимум 3 участника.":
    "Вы не можете удалить участника команды. В команде должно быть минимум 3 участника.",
  "Мои запросы на вступление": "Мои запросы на вступление",
  "Отправить новый запрос": "Отправить новый запрос",
  "Вы можете присоединиться к команде": "Вы можете присоединиться к команде",
  "Ваш запрос на добавление нового участника в команду отправлен на рассмотрение":
    "Ваш запрос на добавление нового участника в команду отправлен на рассмотрение",
  Принять: "Принять",
  Отказать: "Отказать",
  "История заявок": "История заявок",
  "Дата создания": "Дата создания",
  "Тип запроса": "Тип запроса",
  Статус: "Статус",
  "Не прошел": "Не прошел",
  jury: "Жюри",
  "Не состоит ни в одной команде": "Не состоит ни в одной команде",
  "Процесс подачи заявки для участия в конкурсе завершен 31 августа 2023 года":
    "Процесс подачи заявки для участия в конкурсе завершен 31 августа 2023 года",
  "Оценки жюри": "Оценки жюри",
  "Ваш проект еще не оценили члены жюри":
    "Ваш проект еще не оценили члены жюри",
  "Ожидайте оценок": "Ожидайте оценок",
  Посмотреть: "Посмотреть",
  "Средний балл": "Средний балл",
  "В ожидании": "В ожидании",
  "Актуальность предлагаемого стартап-проекта":
    "Актуальность предлагаемого стартап-проекта",
  "Оценка достижимости результатов стартап-проекта":
    "Оценка достижимости результатов стартап-проекта",
  "Оценка потенциальных конкурентных преимуществ проекта":
    "Оценка потенциальных конкурентных преимуществ проекта",
  "Оценка востребованности продукта или услуги на рынке":
    "Оценка востребованности продукта или услуги на рынке",
  "Завершенность продукта": "Завершенность продукта",
  Функциональность: "Функциональность",
  Работоспособность: "Работоспособность",
  "Интерфейс и дизайн": "Интерфейс и дизайн",
  Интеграция: "Интеграция",
  "Оценка потенциала команды": "Оценка потенциала команды",
  Укомплектованность: "Укомплектованность",
  Презентация: "Презентация",
  "Оценка качества ответов на вопросы жюри о проекте":
    "Оценка качества ответов на вопросы жюри о проекте",
  "Командная дисциплина": "Командная дисциплина",
  "Техническая реализация": "Техническая реализация",
  "Использование современных технологий и востребованных языков программирования":
    "Использование современных технологий и востребованных языков программирования",
  "правильно подобранная архитектура для решения задачи и возможности масштабирования":
    "правильно подобранная архитектура для решения задачи и возможности масштабирования",
  "jury-hackathon": "Жюри хакатона",
  hackathon: "Хакатон",
  "Не отобраны 2Х": "Не отобраны 2Х",
  "Не отобраны 3Х": "Не отобраны 3Х",
  "Онлайн оценка": "Онлайн оценка",
  "Жюри неотобранные": "Жюри неотобранные",
  "Жюри отобранные": "Жюри отобранные",
  Оценки: "Оценки",
  "1-этап": "1-этап",
  "2-этап": "2-этап",
  Сертификаты: "Сертификаты",
  "Сертификат участника Хакатон": "Сертификат участника Хакатон",
  Скачать: "Скачать",
  Финал: "Финал",
  "Результаты жюри": "Результаты жюри",
  Направление: "Направление",
  "Количество заявок": "Количество заявок",
  "Направление конкурса": "Направление конкурса",
  "Средняя оценка": "Средняя оценка",
  "Средний балл (1 этап)": "Средний балл (1 этап)",
  "Средний балл (2 этап)": "Средний балл (2 этап)",
  "макс. 50 баллов": "макс. 50 баллов",
  "Перейти на онлайн голосование": "Перейти на онлайн голосование",
  Голосовать: "Голосовать",
  дней: "дней",
  часов: "часов",
  минут: "минут",
  секунд: "секунд",
  "Стартовал этап онлайн-голосования конкурса President Tech Award с общим призовым фондом $1 млн!":
    "Стартовал этап <span>онлайн-голосования</span> конкурса President Tech Award с общим призовым фондом <span>$1 млн!</span>",
  "Каждый голос очень важен! Присоединяйтесь к общественному голосованию, зарегистрировавшись через систему MyID. Поддержите проекты и помогите определить победителей!":
    "<span>Каждый голос очень важен!</span> Присоединяйтесь к общественному голосованию, зарегистрировавшись через систему MyID. Поддержите проекты и помогите определить победителей!",
  "25 лучших команд, отобранных по основным направлениям конкурса ждут, чтобы вы оценили их проекты по следующим критериям:":
    "25 лучших команд, отобранных по основным направлениям конкурса ждут, чтобы вы оценили их проекты по следующим критериям:",
  "Внешний вид (интерфейс и дизайн)": "Внешний вид (интерфейс и дизайн)",
  Удобство: "Удобство",
  Завершенность: "Завершенность",
  Актуальность: "Актуальность",
  "Результаты онлайн голосования": "Результаты онлайн голосования",
  Команда: "Команда",
  Балл: "Балл",
  Голоса: "Голоса",
  Голосование: "Голосование",
  "Выберите категорию проекта": "Выберите категорию проекта",
  "Презентация проекта": "Презентация проекта",
  "Доступы к системе": "Доступы к системе",
  "Логотип проекта": "Логотип проекта",
  "Оцените выбранный проект": "Оцените выбранный проект",
  "Проставьте каждому из пунктов свою оценку. Исходите из вашего личного опыта.":
    "Проставьте каждому из пунктов свою оценку. Исходите из вашего личного опыта.",
  Дизайн: "Дизайн",
  Функционал: "Функционал",
  Удобство: "Удобство",
  Готовность: "Готовность",
  "Дата оценки": "Дата оценки",
  "По критериям": "По критериям",
  "Посмотреть файл": "Посмотреть файл",
  "Регистрация на голосование": "Регистрация на голосование",
  "Пройдите регистрацию и оставьте свой голос за интересный для вас проект":
    "Пройдите регистрацию и оставьте свой голос за интересный для вас проект",
  "Вы уже проголосовали": "Вы уже проголосовали",
  "Ссылка на видеозапись, описывающий проект":
    "Ссылка на видеозапись, описывающий проект",
  Проект: "Проект",
  "Как будут определены победители основного направления конкурса?":
    "Как будут определены победители основного направления конкурса?",
  "Победители будут определены на этапе онлайн голосования, голосование пройдет на основе 5 бальной системы: внешний вид проекта; функционал проекта; удобство проекта; готовность (завершенность) проекта и значимость проекта. Победителями по итогам голосования будут являться проекты набравшие максимальный балл.":
    "Победители будут определены на этапе онлайн голосования, голосование пройдет на основе 5 бальной системы: внешний вид проекта; функционал проекта; удобство проекта; готовность (завершенность) проекта и значимость проекта. Победителями по итогам голосования будут являться проекты набравшие максимальный балл.",
  "Видеоинструкция по голосованию": "Видеоинструкция по голосованию",
  Подробнее: "Подробнее",
  "Ознакомление с финалистами": "Ознакомление с финалистами",
  "Ovoz berish vaqti tugadi": "Время голосования закончилось",
  "President Tech Award —":
    "President Tech Award — это конкурс на лучшие программные продукты по 5 основным и 1 специальному направлению. Целью которого является привлечь внимание молодёжи к сфере IT-технологий и вдохновить их на создание инновационных решений и разработок, способных конкурировать на мировом рынке.",
  LastWinners: "Победители предыдущего сезона:",
  seasontitle: "Технологии на основе искусственного интеллекта:",
  Настройки: "Настройки",
  Редактировать: "Редактировать",
  "Место прописки": "Место прописки",
  Добавить: "Добавить",
  "Технологии в сфере информационной и кибербезопасности":
    "Технологии в сфере информационной и кибербезопасности",
  Регистрация: "Регистрация",
  "Micro-SaaS — это подход к разработке ПО, который использует небольшие, независимые сервисы для создания приложений. Основные преимущества включают гибкость, масштабируемость и упрощение обслуживания.<br/> AdTech — это технологии для управления и оптимизации цифровой рекламы. Включает инструменты для таргетинга, ретаргетинга и автоматизации рекламных кампаний, с целью повышения эффективности и ROI.":
    "Micro-SaaS — это подход к разработке ПО, который использует небольшие, независимые сервисы для создания приложений. Основные преимущества включают гибкость, масштабируемость и упрощение обслуживания.<br/> AdTech — это технологии для управления и оптимизации цифровой рекламы. Включает инструменты для таргетинга, ретаргетинга и автоматизации рекламных кампаний, с целью повышения эффективности и ROI.",
  "Специальное направление: Хакатон": "Специальное направление: Хакатон",
  "Технологические решения в сфере Micro-SaaS и AdTech":
    "Технологические решения в сфере Micro-SaaS и AdTech",
  "Tahrirchi — проект":
    "Tahrirchi — проект, облегчающий написание на узбекском языке, проверяющий орфографию, контекст, стиль и грамматику текстов.",
  "Docum.ai — проект":
    "Docum.ai — проект, полностью автоматизирующий обслуживание клиентов с использованием искусственного интеллекта.",
  "Alama — платформа":
    "Alama — платформа онлайн-курсов на местных языках, использующая искусственный интеллект для перевода текста и речи с лучших онлайн-курсов в мире.",
  "Mentalaba — проект":
    "Mentalaba — проект, помогающий студентам выбирать учебные заведения.",
  "Lalu— мобильная платформа":
    "Lalu — мобильная платформа для молодых и будущих мам, предоставляющая достоверную информацию о беременности и уходе за ребенком.",
  "Uztrip— помощь": "Uztrip — помощь в поиске лучших мест для посещения.",
  "ALATOR — проект":
    "ALATOR — проект по обнаружению и предотвращению киберугроз и уязвимостей в информационных системах.",
  "PCP — проект":
    "PCP — проект, обеспечивающий безопасность цифровых активов и использование интернета.",
  "SHIFO24— инновационный проект":
    "SHIFO24 — инновационный проект в области безопасности онлайн-медицинских услуг.",
  "Yemak — служба доставки еды.": "Yemak — служба доставки еды.",
  "Joyla— торговая площадка":
    "Joyla — торговая площадка для быстрой покупки и продажи товаров онлайн.",
  "IMAN — финтех-платформа":
    "IMAN — финтех-платформа, поддерживающая исламскую банковскую инфраструктуру для банков.",
  "AirPilot — мобильная игра":
    "AirPilot — мобильная игра, стимулирующая интерес к аэродинамике и аэрокосмическим технологиям.",
  "The Rise of Jaloliddin":
    "The Rise of Jaloliddin — историческая игра, отражающая историю народа Туркестана.",
  "The Golden Horde":
    "The Golden Horde: Turan — средневековая экшн-РПГ, основанная на реализме и исторических событиях Средней Азии.",
  "Оставьте заявку и мы оповестим вас о начале принятия заявок.":"Оставьте заявку и мы оповестим вас о начале принятия заявок.",
  "Отлично. Мы отправим вам сообщение, когда откроется регистрация конкурса.":"Отлично. Мы отправим вам сообщение, когда откроется регистрация конкурса.",
  "Процесс создания и формирования команды для участия в конкурсе завершен 25 августа 2024 года":"Процесс создания и формирования команды для участия в конкурсе завершен 25 августа 2024 года",
  "Процесс подачи заявки для участия в конкурсе завершен 25 августа 2024 года":"Процесс подачи заявки для участия в конкурсе завершен 25 августа 2024 года",

  "Процесс подачи заявки для участия в конкурсе завершен 30 августа 2024 года":"Процесс подачи заявки для участия в конкурсе завершен 30 августа 2024 года",
  "Процесс создания и формирования команды для участия в Хакатоне завершен 30 августа 2024 года.":"Процесс создания и формирования команды для участия в Хакатоне завершен 30 августа 2024 года.",
  "Презентация прикреплена":"Презентация прикреплена",
  "Оценка поставлена команде": "Оценка поставлена команде",
  "Чекпоинты Хакатона | Чекпоинт 1":"Чекпоинты Хакатона | Чекпоинт 1",
  "Чекпоинты Хакатона | Чекпоинт 2":"Чекпоинты Хакатона | Чекпоинт 2",
  "Чекпоинты Хакатона | Чекпоинт 3":"Чекпоинты Хакатона | Чекпоинт 3",
  "Чекпоинты Хакатона":"Чекпоинты Хакатона",
  "Чекпоинт 1":"Чекпоинт 1",
  "Чекпоинт 2":"Чекпоинт 2",
  "Чекпоинт 3":"Чекпоинт 3",
  "Описание":"Описание",
  "Оценочный лист":"Оценочный лист",
  "Найменование критерия": "Найменование критерия",
  "Оценки":"Оценки",
  "ВСЕГО":"ВСЕГО",
  "Подтвердить":"Подтвердить",
  "Баллы успешно отправлены!":"Баллы успешно отправлены!",
  "Ссылка на Github добавлена":"Ссылка на Github добавлена",
  "Программа":"Программа",
  "Победители сезона":" Победители сезона",
  "Технологии искусственного интеллекта":"Технологии искусственного интеллекта",
  "TASS Vision — проект":"B2B SaaS платформа, использующая Generative AI и Computer Vision, чтобы помочь офлайн-ритейлерам увеличить продажи , улучшить клиентский опыт и предоставить полезную аналитику для повышения эффективности.",
  "Speaklish — проект":"Платформа с искусственным интеллектом для оценки устной речи, которая помогает улучшить навыки общения на английском, повысить уверенность и улучшить результаты IELTS с помощью персонализированного обучения",
  "Cradle — проект":"Решение на базе компьютерного зрения для банковской сферы, образования, производства и ритейла, автоматизирующее процессы, повышающее безопасность и улучшающее пользовательский опыт.",
  "Online Hamshira — проект":"латформа, соединяющая пациентов с медицинскими специалистами, предоставляющая удобные услуги на дому и позволяющая медицинским работникам получать дополнительный доход.",
  "Stepogram— проект":"Фитнес-приложение, которое превращает активный образ жизни в увлекательное занятие с помощью соревновательных вызовов и мотивирует к здоровому и активному образу жизни.",
  "Braille Recognition — проект":"Приложение с искусственным интеллектом, которое преобразует символы шрифта Брайля в текст, упрощая общение для семей и педагогов, работающих с людьми с нарушением зрения.",
  "OYGUL — проект":" Платформа для шопинга на базе искусственного интеллекта, предоставляющая персонализированные рекомендации и стильные подборки для удобного и увлекательного опыта покупок.",
  "StudentHunter — проект":"Платформа, упрощающая подачу заявок в колледжи и на вакансии с автоматическим подбором данных, позволяя студентам бесплатно подавать заявки в несколько учреждений.",
  "EduTizim — проект":" Многофункциональная платформа CRM и LMS для образовательных учреждений, которая оптимизирует управление, улучшает обучение и повышает эффективность благодаря инструментам для отслеживания студентов, курсов и оценок.",
  "Технологические решения в области Micro-SaaS и AdTech":"Технологические решения в области Micro-SaaS и AdTech",
  "Gale-Force Entertainment — проект":"Приключенческая ролевая игра в жанре экшн, основанная на бестселлере, в которой игроки следуют за Корнелиусом в его стремлении раскрыть тайны, решить головоломки и найти части Бравешипа в таинственном мире.",
  "Fazliddin Games — проект":"Физическая игра, в которой игроки управляют тряпичной куклой в виде сахарного кубика, собирая еду и преодолевая препятствия в виде ИИ-управляемой клубники, динамической гравитации и назойливых противников.",
  "Grimoire Entertainment — проект":"Игра-платформер, сочетающая классическую механику с современным дизайном, включающая в себя обмен персонажами, головоломки и захватывающую сюжетную линию.",
  "Jobster — проект":"Платформа для найма, которая быстро находит качественных кандидатов, повышает эффективность HR и обеспечивает прозрачность процесса.",
  "Sug‘urta Bozor — проект":"Комплексное решение для страховых компаний, включающее API-маркетплейс, премиум-калькулятор и инструмент для проверки автомобилей с обнаружением повреждений на основе ИИ.",
  "Diip.uz — проект":"Платформа для транспортной логистики с прямым доступом к проверенным грузовикам и грузам, а также инструментами CRM для аналитики и управления отношениями.",
};
